import React, { useState, useEffect } from "react";
import './quartos.css';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Carousel from '../Carousel/Carrousel/index'; 

const QuartoR = ({titulo, descricao, btnFotos, imagem, alt, icons, Banner1, Banner2, Banner3  }) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [iconList, setIconList] = useState([])
    useEffect(() => {
        setIconList (
            icons
        )
    },[]);

    return (
        <section className="wrapper-quartos-joinville">
            <div className="bg-quartoR-joinville">
                <div className="info-wrapper-quartoR-joinville">
                    <h3>{titulo}</h3>
                    <p>{descricao}</p>

                    <div className="joinville-wrapper-icones-quarto">
                        {
                            iconList.map(
                                (item, index) => {
                                    return (
                                        <div key={index}>
                                            <i>
                                                {item.icone}
                                            </i>
                                            <h4>{item.info}</h4>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>

                    <div className="joinville-wrapper-btn-quarto">
                        <button type="button" onClick={handleOpen}>
                            {btnFotos}
                        </button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="quartoLeft-modal-wrap">
                                <div className="quartoleft-wrap-carousel">
                                    <Carousel>
                                        <img src={Banner1} alt="Suites - Hotel Le Canard"/>
                                        <img src={Banner2} alt="Suites - Hotel Le Canard"/>
                                        <img src={Banner3} alt="Suites - Hotel Le Canard"/>
                                    </Carousel>
                                </div>

                                <main className="modal-wrapper-Ql">
                                    <div className="quartoleft-content-modal">
                                        <h3>{titulo}</h3>
                                        <p>
                                            {descricao} 
                                        </p>
                                    </div>
                                    <div className="modal-icons-wrap">
                                        {
                                            iconList.map(
                                                (item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <i>
                                                                {item.icone}
                                                            </i>
                                                            <h4>{item.info}</h4>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }   
                                    </div>
                                </main>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
            <div className="img-wrapper-quartoR-joinville">
                <img src={imagem} alt={alt}/>
            </div>
        </section>
    )
}

export default QuartoR;