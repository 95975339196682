import React from 'react';
import { Link } from 'react-router-dom';
    // Rotas 
import Carousel from '../Carousel/Carrousel';
    // Imagens
import Banner1 from '../../assets/01.jpeg';
import Banner2 from '../../assets/02.jpeg';
import Banner3 from '../../assets/03.jpeg';

const SalaR = () => {

    const InfoSalas = {
        altPadrao: 'Salas de reunião - Hotel Le Canard',
    }
    return (
        <section className='salaR-wrapper'>
            
            <div className='salaR-wrapper-left'>
                <div className='salaR-img-wrapper'>
                    <div className='salaR-carousel-wrap'>
                        <Carousel>
                            <img src={Banner1} alt={`${InfoSalas.altPadrao}`}/>
                            <img src={Banner2} alt={`${InfoSalas.altPadrao}`}/>
                            <img src={Banner3} alt={`${InfoSalas.altPadrao}`}/>
                        </Carousel>
                    </div>
                </div>
            </div>
            
            <div className='salaR-wrapper-right'>
                <div className='salaR-info-wrapper'>
                    <div className='salaR-texto-wrapper'>
                        <h2>Lages</h2>
                        <p>
                            LeCanard Lages possui o espaço ideal para o sucesso do seu evento.
                        </p>
                    </div>
                    <div className='salaR-list-wrapper'>
                        <ul className='salaR-nome-salas'>
                            <li><h4>Auditório</h4></li>
                            <li><h4>Braço Universitário</h4></li>    
                            <li><h4>Espinha de peixe</h4></li>        
                        </ul>

                        <ul>
                            <li><h4>Sala 01</h4></li>
                            <li><p>90 pessoas</p></li>
                            <li><p>30 pessoas</p></li>        
                            <li><p>50 pessoas</p></li>        
                        </ul> 
                    </div>
                    <div className='salasL-wrapper-btn'>
                        <Link title="Clique para agendar seu evento!" to="/contato" alt="Agende seu evento!">
                            Agende seu evento!
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SalaR;