import React from 'react';
import { Route, Routes } from 'react-router-dom'
import './App.css';

import ScrollToTop from './context/ScrollTop';

import Header from './components/Header';
import Home from './pages/Home';
import UnJoinville from './pages/Joinville';
import UnLages from './pages/Lages';
import Lazer from './pages/Lazer';
import Eventos from './pages/Eventos/Index';
import Cliente from './pages/Cliente';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import BtnWhats from './components/BtnWhats';

function App() {
  return (
    <>
      <ScrollToTop />
        <Header />
        <BtnWhats/>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path="/joinville" element={<UnJoinville />} />
          <Route exact path="/lages" element={<UnLages />} />
          <Route exact path="/lazer" element={<Lazer />} />
          <Route exact path="/eventos" element={<Eventos />} />
          <Route exact path='/cliente' element={<Cliente />} />
          <Route exact path='/contato' element={<Contact />} />
        </Routes>
        <Footer />
    </>
  );
}

export default App;
