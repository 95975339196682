import React, { memo } from 'react'
import { RiInstagramFill, RiFacebookBoxFill } from 'react-icons/ri';
import { FiMail, FiPhone } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { BiMap } from 'react-icons/bi';
import './styles.css';

import { LogoInteira } from '../../assets/icons';

function Footer() {
    
    const infoFooter = {
        instagramLink: 'https://www.instagram.com/lecanardhoteis/',
        facebookLink: 'https://www.facebook.com/lecanardhoteis',
    }
    
    return (
        <div className='footer-container' >

            <div className='footer-row' style={{ marginLeft: "auto", marginRight: "auto" }} >
                <div className='footer-item' >
                    <div className='footer-item-icon' >
                        <Link to="/">
                            <LogoInteira/>
                        </Link>
                    </div>
                    <div className='row' >
                        <a className='footer-btn-redes-sociais' href={`${infoFooter.instagramLink}`} target="blank">
                            <RiInstagramFill className='footer-social-icon' style={{ marginLeft: "auto" }} />
                        </a>
                        <a className='footer-btn-redes-sociais' href={`${infoFooter.facebookLink}`} target="blank">
                            <RiFacebookBoxFill className='footer-social-icon' style={{ marginRight: "auto" }} />
                        </a>
                    </div>
                </div>

                <div className='footer-item' >
                    <Link className='footer-item-title' to="/joinville">Joinville</Link>
                    <div className='row' >
                        <BiMap className='footer-item-icon-short' />
                        <a className='footer-item-text' href="https://goo.gl/maps/vFpnWUvf42cFmNDz9" target="blank">Rua XV De Novembro, 2075 | Joinville/SC</a>
                    </div>
                    <div className='row' >
                        <FiMail className='footer-item-icon-short' />
                        <a className='footer-item-text' href="mailto:joinville@lecanard.com.br">joinville@lecanard.com.br</a>
                    </div>
                    <div className='row' >
                        <FiPhone className='footer-item-icon-short' />
                        <a href="tel:554734335033" className='footer-item-text' >47 3433-5033</a>
                    </div>
                </div>

                <div className='footer-item' >
                    <Link className='footer-item-title' to="/lages">Lages</Link>
                    <div className='row' >
                        <BiMap className='footer-item-icon-short' />
                        <a className='footer-item-text' href="https://goo.gl/maps/uo6t58AjjXfa1AvRA" target="blank">Avenida Presidente Vargas, 255 | Lages/SC</a>
                    </div>
                    <div className='row' >
                        <FiMail className='footer-item-icon-short' />
                        <a className='footer-item-text' href="mailto:lages@lecanard.com.br">lages@lecanard.com.br</a>
                    </div>
                    <div className='row' >
                        <FiPhone className='footer-item-icon-short' />
                        <a className='footer-item-text' href="tel:554921018100">49 2101-8100</a>
                    </div>
                </div>
            </div>
            <div className='row' style={{ marginLeft: "auto", marginRight: "auto" }} >
                <div style={{ color: "#10213A", fontSize: "16px", marginBottom: "5px", paddingBottom: '10px' }} >&copy; 2024 VEGA IT - Todos os Direitos Reservados</div>
            </div>
        </div>
    )
}

export default memo(Footer)