import React, { memo, useRef, useState, useEffect } from 'react'
import { HiOutlineChevronDown } from 'react-icons/hi';
import { FaRegCalendarAlt } from 'react-icons/fa';

import './styles.css';

function InputDate({ state, setState, label, min, max }) {

    const inputRef = useRef()
    const minDate = min ? `${min.getFullYear()}-${min.getMonth() + 1 < 10 ? `0${min.getMonth() + 1}` : min.getMonth() + 1}-${min.getDate() < 10 ? `0${min.getDate()}` : min.getDate()}` : ''
    const maxDate = max ? `${max.getFullYear()}-${max.getMonth() + 1 < 10 ? `0${max.getMonth() + 1}` : max.getMonth() + 1}-${max.getDate() < 10 ? `0${max.getDate()}` : max.getDate()}` : ''
    const [value, setValue] = useState(`${state.getFullYear()}-${state.getMonth() + 1 < 10 ? `0${state.getMonth() + 1}` : state.getMonth() + 1}-${state.getDate() < 10 ? `0${state.getDate()}` : state.getDate()}`)

    const formatDate = (item) => {
        var data = item.split('-')
        return `${data[2]}/${data[1]}/${data[0]}`
    }

    useEffect(() => {
        var data = value.split('-')
        setState(new Date(data[0], data[1], data[2]))
    }, [value])

    return (
        <div style={{ marginTop: 'auto', marginBottom: 'auto' }} >
            <div className='header-item-label' > {label} </div>
            <div className='header-item-input' onClick={() => inputRef.current.showPicker()} >
                <input
                    style={{ opacity: '0', position: 'absolute', zIndex: '-1' }}
                    ref={inputRef}
                    type={'date'}
                    value={value} onChange={(e) => setValue(e.target.value)}
                    min={minDate}
                    max={maxDate}
                />

                < FaRegCalendarAlt className='header-item-icon' />
                <div className='header-item-text'  > {formatDate(value)} </div>
                <HiOutlineChevronDown className='chevron-down' />
            </div>
        </div>
    )
}

export default memo(InputDate)