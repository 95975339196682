import React from 'react';

const EnvIcone = ({NewIcons, informacaodoIcone}) => {
    
    const WrapperIcone = {
        width: '150px',
        height: '150px',
    }
    const EspacamentoIcone = {
        marginLeft: '46px',
    }
    
    return (
        <div style={WrapperIcone}>
            <i style={EspacamentoIcone}>
                {NewIcons}
            </i>
            <h4>{informacaodoIcone}</h4>
        </div>
    ) 
}

export default EnvIcone;