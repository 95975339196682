import React from 'react';
import { GiCoffeeCup, GiWeightLiftingUp, GiTable, GiShower, GiSnowflake1, GiMirrorMirror } from 'react-icons/gi';
import { BiFridge, BiBed, BiFullscreen } from 'react-icons/bi'
import { CgScreen } from 'react-icons/cg';
import { FaWifi } from 'react-icons/fa';
import './Lages.css';

// Rotas 

import BannerHotel from '../../components/BannerHotel';
import GroupIcons from '../../components/GroupIcons';
import EnvIcone from '../../components/EnvIcone';
import QuartoR from '../../components/Quartos/quartosRight';
import QuartoL from '../../components/Quartos/QuartoLeft';
import CafedaManha from '../../components/CafedaManha/';
import Localizacao from '../../components/localizacao';
import Estacionamento from '../../components/Estacionamento';

// Imagens 

import RestauranteImg from '../../assets/cafe_da_manha_lages.webp';
import ImagemEstacionamento from '../../assets/estacionamento_lages.webp';
import MapaImage from '../../assets/Localizacao_Le_Canard_Lages.webp';

import Banner from '../../assets/BannerLages.webp';
import BannerQuartoRight from '../../assets/ExecutiveTwinLages.jpeg';

import ExecutiveDuploLages1 from '../../assets/ExecutiveDuploLages1.webp';
import ExecutiveDuploLages2 from '../../assets/ExecutiveDuploLages2.webp';
import DuploTwin from '../../assets/DuploTwin.webp';

import SuperiorDuplo1 from '../../assets/SuperiorDuploLages1.webp';
import SuperiorDuplo2 from '../../assets/SuperiorDuploLages2.webp';

import SuperiorLuxo from '../../assets/SuperiorLuxoLages01.webp';
import SuperiorLuxo2 from '../../assets/SuperiorLuxoLages02.webp';
import SuperiorLuxo3 from '../../assets/SuperiorLuxoLages03.webp';

import LuxoConjugado1 from '../../assets/LuxoConjulgado01.png'
import LuxoConjugado2 from '../../assets/LuxoConjulgado02.png'
import LuxoConjugado3 from '../../assets/LuxoConjulgado03.png'

import SuiteMaster01 from '../../assets/SuiteMasterLages1.png';
import SuiteMaster02 from '../../assets/SuiteMasterLages2.png';

import luxoTorreNova1 from '../../assets/rooms/lages/luxo-torre-nova/luxo-torre-nova-1.webp'
import luxoTorreNova3 from '../../assets/rooms/lages/luxo-torre-nova/luxo-torre-nova-3.webp'
import luxoTorreNova4 from '../../assets/rooms/lages/luxo-torre-nova/luxo-torre-nova-4.webp'
// import luxoTorreNova5 from '../../assets/rooms/lages/luxo-torre-nova/luxo-torre-nova-5.webp'
// import luxoTorreNova8 from '../../assets/rooms/lages/luxo-torre-nova/luxo-torre-nova-8.webp'
// import luxoTorreNova9 from '../../assets/rooms/lages/luxo-torre-nova/luxo-torre-nova-9.webp'
// import luxoTorreNova10 from '../../assets/rooms/lages/luxo-torre-nova/luxo-torre-nova-10.webp'

const UnLages = () => {

    const InfosSeo = {
        titulo: 'Hotel Le Canard - Lages',
        descricao: 'Apontado como um dos melhores hotéis da região, as acomodações foram pensadas para pessoas exigentes como você, que além de ter tudo à sua disposição, irá se surpreender com a ala exclusiva Le Canard Luxo. Reconhecido como o hotel mais bem localizado da cidade e o melhor em opções de lazer. ',
        AltPadrao: 'Hotel Le Canard - Joinville',
    }
    const InfoIcones = {
        info01: 'Café da manha',
        info02: 'Academia 24h',
        info03: 'Wi-fi',
        info04: 'Frigobar',
        info05: 'Mesa de Trabalho',
        info06: 'Duchas com pressurizador individual',
        info07: 'Ar condicionado split quente e frio',
    }
    const infoProdutos = {
        descriptionIcone01: 'TV LED 32"',
        descriptionIcone02: '2 Camas Solteiro',
        descriptionIcone03: '16 m²',
        descriptionIcone04: 'Cama Queen',
        descriptionIcone05: '2 Camas Solteiro',
        descriptionIcone06: 'TV Smart 32"',
        descriptionIcone07: '2 Camas Solteiro King',
        descriptionIcone08: '28 m²',
        descriptionIcone09: 'Hidromassagem',
        descriptionIcone10: 'Espelho Maquiagem',
        descriptionIcone11: '2 Camas Queen',
        descriptionIcone12: '2 TVs Smart 32"',
        descriptionIcone13: '58 m²',
        descriptionIcone14: 'Cama Casal King',
        descriptionIcone15: 'Mesa',
        descriptionIcone16: '2 Camas Casal',
        botaoReserva: 'Quero reservar',
        botaoFotos: 'Ver mais fotos',
    }
    const infoProdutosQR = {
        titulo: 'Executivo Twin',
        titulo02: 'Duplo Twin',
        titulo03: 'Luxo Superior Duplo',
        titulo04: 'Luxo Superior - Ala Feminina',
        titulo05: 'Luxo Superior - Família',
        titulo06: 'Apto Familia Luxo Conjugado',
        titulo07: 'Suíte Master - 2 Ambientes',
        titulo08: 'LUXO - Torre Nova',
        describe: 'O Executivo Twin conta com um ambiente aconchegante, no qual possui 2 camas box de solteiro, TV LED 32 polegadas, frigobar abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
        describe02: 'O Duplo Twin conta com um ambiente aconchegante, no qual possui 2 camas box de solteiro, TV LED 32 polegadas, frigobar abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
        describe03: 'O Superior Duplo, conta com um ambiente aconchegante, no qual possui 01 cama box de casal, TV Smart 32 polegadas, melhor isolamento  acústico, além de todas as outras vantagens e comodidades ofecidas pelo Le Canard ',
        describe04: 'Pensando no bem estar e na privacidade feminina, o Le Canard possui uma ala de quartos exclusiva para mulheres. Além de tudo que é oferecido nos quartos Superiores, esses ainda contam com um espelho de quase 2m, chapinha e um secador super potente.',
        describe05: 'O Superior Duplo, conta com um ambiente aconchegante,no qual possui 2 camas box de casal, TV Smart 32 polegadas, melhor isolamento acústico, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.', 
        describe06: 'O Apto Familia Luxo Conjugado está situada em uma nova ala do hotel, contando com decoração moderna, dois ambientes individuais, duas televisões, melhor isolamento acústico, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
        describe07: 'A Suíte Master conta com um ambiente aconchegante, no qual possui uma cama casal king, duas TV LED 32 polegadas, dois ambientes, além de todas as comodidades e vantagens oferecidas no Le Canard.',
        describe08: 'O Apto Luxo Torre nova, está situado em uma nova ala do hotel, contando com uma decoração moderna, televisão maior Smart 43 polegads, 1 cama box de casal, com vista panorâmica, além de todas as outras vantagens e comodidades oferecidas pelo  Le Canard. Moderno e aconchegante!'
    }
    const infoProdutosQL = {
        titulo: 'Executivo Duplo',
        descricao: 'O Executivo Duplo conta com um ambiente aconchegante, no qual possui 1 cama box queen, TV LED 32 polegadas, frigobar abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
        descricao02: 'O Superior Twin está situado em uma nova ala do hotel, contando assim com uma decoração mais moderna, televisão maior, um melhor isolamento acústico, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
        descricao03: 'A Suíte Superior está situada em uma nova ala do hotel, contando com uma decoração moderna, televisão maior, melhor isolamento acústico, uma banheira com hidromassagem, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
    }
    const InfoLocalizacao = {
        endereco: 'Avenida Presidente Vargas, 255 | Lages/SC',
        email: 'lages@lecanard.com.br',
        telefone: '49 2101-8100',
    }

    return (
        <>
            <BannerHotel banner={Banner} titulo={`${InfosSeo.titulo}`} describe={`${InfosSeo.descricao}`} />

            <GroupIcons>
                <EnvIcone informacaodoIcone={`${InfoIcones.info01}`} NewIcons={<GiCoffeeCup />} />
                <EnvIcone informacaodoIcone={`${InfoIcones.info02}`} NewIcons={<GiWeightLiftingUp />} />
                <EnvIcone informacaodoIcone={`${InfoIcones.info03}`} NewIcons={<FaWifi />} />
            </GroupIcons>
            <GroupIcons>
                <EnvIcone informacaodoIcone={`${InfoIcones.info04}`} NewIcons={<BiFridge />} />
                <EnvIcone informacaodoIcone={`${InfoIcones.info05}`} NewIcons={<GiTable />} />
                <EnvIcone informacaodoIcone={`${InfoIcones.info06}`} NewIcons={<GiShower />} />
                <EnvIcone informacaodoIcone={`${InfoIcones.info07}`} NewIcons={<GiSnowflake1 />} />
            </GroupIcons>

            <QuartoR
                titulo={`${infoProdutosQR.titulo}`}
                descricao={`${infoProdutosQR.describe}`}
                btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${InfosSeo.AltPadrao}`} imagem={BannerQuartoRight}
                icons=
                {[
                    { info: `${infoProdutos.descriptionIcone01}`, icone: <CgScreen /> },
                    { info: `${infoProdutos.descriptionIcone02}`, icone: <BiBed /> },
                    { info: `${infoProdutos.descriptionIcone03}`, icone: <BiFullscreen /> }
                ]}
                Banner1={BannerQuartoRight} Banner2={BannerQuartoRight} Banner3={BannerQuartoRight}
            />
            <QuartoL
                Image={ExecutiveDuploLages1}
                alt={`${InfosSeo.AltPadrao}`}
                titulo={`${infoProdutosQL.titulo}`} description={`${infoProdutosQL.descricao}`}
                BtnReserva={`${infoProdutos.botaoReserva}`} BtnFotos={`${infoProdutos.botaoFotos}`}
                icones=
                {[
                    { icone: <CgScreen />, infoIcone: `${infoProdutos.descriptionIcone01}` },
                    { icone: <BiBed />, infoIcone: `${infoProdutos.descriptionIcone04}` },
                    { icone: <BiFullscreen />, infoIcone: `${infoProdutos.descriptionIcone03}` }
                ]}
                Banner1={ExecutiveDuploLages1} Banner2={ExecutiveDuploLages2} Banner3={ExecutiveDuploLages1}
            />
            <QuartoR
                titulo={`${infoProdutosQR.titulo02}`}
                descricao={`${infoProdutosQR.describe02}`}
                btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${InfosSeo.AltPadrao}`} imagem={DuploTwin}
                icons=
                {[
                    { info: `${infoProdutos.descriptionIcone01}`, icone: <CgScreen /> },
                    { info: `${infoProdutos.descriptionIcone05}`, icone: <BiBed /> },
                    { info: `${infoProdutos.descriptionIcone03}`, icone: <BiFullscreen /> }
                ]}
                Banner1={DuploTwin} Banner2={DuploTwin} Banner3={DuploTwin}
            />

            <QuartoL
                Image={SuperiorDuplo1} alt={`${InfosSeo.AltPadrao}`}
                titulo={`${infoProdutosQR.titulo03}`}
                description={`${infoProdutosQR.describe03}`}
                BtnReserva={`${infoProdutos.botaoReserva}`} BtnFotos={`${infoProdutos.botaoFotos}`}
                icones=
                {[
                    { icone: <CgScreen />, infoIcone: `${infoProdutos.descriptionIcone06}` },
                    { icone: <BiBed />, infoIcone: `${infoProdutos.descriptionIcone04}` },
                    { icone: <BiFullscreen />, infoIcone: `${infoProdutos.descriptionIcone08}` }
                ]}
                Banner1={SuperiorDuplo1} Banner2={SuperiorDuplo2} Banner3={SuperiorDuplo1}
            />

            <QuartoR
                titulo={`${infoProdutosQR.titulo05}`}
                descricao={`${infoProdutosQR.describe05}`}
                btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${InfosSeo.AltPadrao}`} imagem={SuperiorLuxo}
                icons=
                {[
                    { info: `${infoProdutos.descriptionIcone06}`, icone: <CgScreen /> },
                    { info: `${infoProdutos.descriptionIcone16}`, icone: <BiBed /> },
                    { info: `${infoProdutos.descriptionIcone08}`, icone: <BiFullscreen /> },
                    // { info: `${infoProdutos.descriptionIcone09}`, icone: <GiBathtub /> }
                ]}
                Banner1={SuperiorLuxo} Banner2={SuperiorLuxo2} Banner3={SuperiorLuxo3}
            />

            <QuartoL
                Image={SuperiorLuxo}
                alt={`${InfosSeo.AltPadrao}`}
                titulo={`${infoProdutosQR.titulo04}`}
                description={`${infoProdutosQR.describe04}`}
                BtnReserva={`${infoProdutos.botaoReserva}`} BtnFotos={`${infoProdutos.botaoFotos}`}
                icones=
                {[
                    { infoIcone: `${infoProdutos.descriptionIcone06}`, icone: <CgScreen /> },
                    { infoIcone: `${infoProdutos.descriptionIcone04}`, icone: <BiBed /> },
                    { infoIcone: `${infoProdutos.descriptionIcone08}`, icone: <BiFullscreen /> },
                    { infoIcone: `${infoProdutos.descriptionIcone10}`, icone: <GiMirrorMirror /> }
                ]}
                Banner1={SuperiorLuxo} Banner2={SuperiorLuxo2} Banner3={SuperiorLuxo3}
            />
            
            <QuartoR
                titulo={`${infoProdutosQR.titulo06}`}
                descricao={`${infoProdutosQR.describe06}`}
                btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${InfosSeo.AltPadrao}`} imagem={LuxoConjugado1}
                icons=
                {[
                    { info: `${infoProdutos.descriptionIcone12}`, icone: <CgScreen /> },
                    { info: `${infoProdutos.descriptionIcone04}`, icone: <BiBed /> },
                    { info: `${infoProdutos.descriptionIcone02}`, icone: <BiBed /> },
                    { info: `${infoProdutos.descriptionIcone13}`, icone: <BiFullscreen /> },
                ]}
                Banner1={LuxoConjugado1} Banner2={LuxoConjugado2} Banner3={LuxoConjugado3}
            />
            <QuartoL
                Image={SuiteMaster01}
                alt={`${InfosSeo.AltPadrao}`}
                titulo={`${infoProdutosQR.titulo07}`}
                description={`${infoProdutosQR.describe07}`}
                BtnReserva={`${infoProdutos.botaoReserva}`} BtnFotos={`${infoProdutos.botaoFotos}`}
                icones=
                {[
                    { infoIcone: `${infoProdutos.descriptionIcone12}`, icone: <CgScreen /> },
                    { infoIcone: `${infoProdutos.descriptionIcone14}`, icone: <BiBed /> },
                    { infoIcone: `${infoProdutos.descriptionIcone15}`, icone: <GiTable /> },
                    { infoIcone: `${infoProdutos.descriptionIcone13}`, icone: <BiFullscreen /> }
                ]}
                Banner1={SuiteMaster01} Banner2={SuiteMaster02} Banner3={SuiteMaster01}
            />

            <QuartoR
                titulo={`${infoProdutosQR.titulo08}`}
                descricao={`${infoProdutosQR.describe08}`}
                btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} 
                alt={`${InfosSeo.AltPadrao}`} 
                imagem={luxoTorreNova1}
                icons=
                {[
                    { info: `TV Smart 43"`, icone: <CgScreen /> },
                    { info: `Cama Queen`, icone: <BiBed /> },
                    { info: `20 m²`, icone: <BiFullscreen /> },
                ]}
                Banner1={luxoTorreNova3} 
                Banner2={luxoTorreNova4} 
                Banner3={luxoTorreNova1}
            />

            <div className='Lages-espacamento-cfd'></div>

            <CafedaManha imgSrc={RestauranteImg} />
            <Estacionamento imgSrc={ImagemEstacionamento} descricao={'O hotel possui um estacionamento próprio privativo, com ampla quantidade de vagas.'} />
            <Localizacao
                email={`${InfoLocalizacao.email}`}
                endereco={`${InfoLocalizacao.endereco}`}
                telefone={`${InfoLocalizacao.telefone}`}
                imgSrc={MapaImage}
                urlMaps={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14115.91898580794!2d-50.3185858!3d-27.8103943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x698e6ce8c974a15a!2sHotel%20Le%20CanarD%20Lages!5e0!3m2!1spt-BR!2sbr!4v1665496858269!5m2!1spt-BR!2sbr'}
            />
        </>
    )
}
export default UnLages;