import React from "react";
import './style.css';


const Estacionamento = ({ descricao, imgSrc }) => {

    return (
        <section className="est-wrapper">
            <div className="est-wrapper-left">
                <div className="est-absolut-image-wrap">
                    <img title="Hotel Le Canard" src={imgSrc} alt=" Estacionamento Hotel Le Canard" />
                </div>
            </div>
            <div className="est-info-wrapper-right">
                <div className="est-info-wrap-text">
                    <h2>Estacionamento</h2>
                    <div className="est-alinhamento-texto">
                        <p>{descricao}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Estacionamento;