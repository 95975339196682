import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './style.css';

const BtnWhats = () => {

    const [openBottonWhats, setOpenBottonWhats ] = useState(false)

    const infoWhats = {
        joinville: 'http://api.whatsapp.com/send?phone=554734335033&text=Ola',
        lages: 'http://api.whatsapp.com/send?phone=554991100244&text=Ola',
    }

    return (
        <main className='whats-wrapper'>
            <div style={{display: openBottonWhats ? 'flex' : 'none' }} className='whats-options-wrap'>
                <a href={`${infoWhats.joinville}`} target="blank" alt="Hotel Le Canard - Joinville">
                    joinville
                </a>
                <a href={`${infoWhats.lages}`} target="blank" alt="Hotel Le Canard - Lages">
                    Lages
                </a>
            </div>
            <div onClick={() => setOpenBottonWhats(!openBottonWhats)} className='whats-botton-wrap' title="Fale Conosco" alt="Fale Conosco">
                <FaWhatsapp/> 
            </div>
        </main>
    )
}

export default BtnWhats;