import React from 'react';
import './Cliente.css';

// Rotas
import BannerHotel from '../../components/BannerHotel';
import HotelLink from '../../components/HotelLink'
import Cliente from '../../components/Cliente';

// Imagens
import Banner from '../../assets/BannerCliente.webp';

const ClientePage = () => {
    
    const InfoCliente = {
        titulo: 'Cliente Habitue',
        tituloHabitue: 'Cliente Habitue',
        describeHabitue: 'O Le Canard hotéis possui um tipo especial de cliente, o Habitue. Essa categoria foi criada pensando nos hóspedes que se hospedam diversas vezes, que garantem assim vantagens e benefícios exclusivos.',
        subTiulo01: 'Tarifa diferenciada',
        subTitulo02: 'Apenas 1 tarifa',
        describeSub01: 'Quanto mais room nights utilizadas, menor a tarifa no decorrer do tempo',
        describeSub02: 'É cobrada apenas o ICMS, por ser uma obrigatório.',
    }

    return (
        <>
            <BannerHotel banner={Banner} titulo={`${InfoCliente.titulo}`}/>
            <div className='cliente-espacamento-habitue'></div>
            <Cliente 
                titulo={`${InfoCliente.tituloHabitue}`} descricao={`${InfoCliente.describeHabitue}`} 
                subTitleOne={`${InfoCliente.subTiulo01}`} subTitleTwo={`${InfoCliente.subTitulo02}`}
                describeSubOne={`${InfoCliente.describeSub01}`} 
                describeSubTwo={`${InfoCliente.describeSub02}`}
            />
            <div className='cliente-espacamento-habitue-bottom'></div>
            <HotelLink/>
        </>
    )
}

export default ClientePage;