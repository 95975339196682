import React from "react";
import './style.css';

const CoffeBreak = () => {
    
    return (
        <section className="breakCoffe-wrapper">
            <div className="breakCoffe-info-wrapper">
                <h2>Coffee Break</h2>
                <p>
                    O menu do coffee break traz três opções de combinações 
                    sempre com a mais alta qualidade e o menu coquetel do 
                    Le Canard apresenta 2 opções com deliciosas combinações. 
                    A rede de hotéis Le Canard conta com restaurantes 
                    terceirizados que trazem em seu cardápio pratos com 
                    excelente qualidade.
                </p>
            </div>
        </section>
    )
}

export default CoffeBreak;