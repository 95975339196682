import React, { useState, useEffect } from "react";

    // Rotas 

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Carousel from '../Carousel/Carrousel/index';

const QuartoL = ({Image, alt, titulo, description, BtnFotos, icones, Banner1, Banner2, Banner3 }) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [iconList, setIconList] = useState([])
    useEffect(() => {
        setIconList (
            icones
        )
    },[]);

    return (
        <section className='joinville-wrapper-QuartoLeft'>
            <div className="joinville-wrapper-image-QuartoLeft">
                <img src={Image} alt={alt}/>
            </div>
            <div className="joinville-wrapper-info-QuartoLeft">
                <div className="joinville-wrapper-texto-QuartoLeft">
                    <h3>{titulo}</h3>
                    <p>{description}</p>
                    <div className="joinville-wrapper-icones-quarto">
                        {
                            iconList.map(
                                (item, index) => {
                                    return (
                                        <div key={index}>
                                            <i>
                                                {item.icone}
                                            </i>
                                            <h4>{item.infoIcone}</h4>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                    <div className="joinville-wrapper-botoes-QuartoLeft">
                        <a onClick={handleOpen}>
                            {BtnFotos}
                        </a>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="quartoLeft-modal-wrap">
                                <div className="quartoleft-wrap-carousel">
                                    <Carousel>
                                        <img src={Banner1} alt="Suites - Hotel Le Canard"/>
                                        <img src={Banner2} alt="Suites - Hotel Le Canard"/>
                                        <img src={Banner3} alt="Suites - Hotel Le Canard"/>
                                    </Carousel>
                                </div>
                                <main className="modal-wrapper-Ql">
                                    <div className="quartoleft-content-modal">
                                        <h3>{titulo}</h3>
                                        <p>
                                            {description} 
                                        </p>
                                    </div>
                                    <div className="modal-icons-wrap">
                                        {
                                            iconList.map(
                                                (item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <i>
                                                                {item.icone}
                                                            </i>
                                                            <h4>{item.infoIcone}</h4>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }   
                                    </div>
                                </main>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default QuartoL;