import React from 'react';
import { MdChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './style.css';

const HotelLink = () => {

    return (
        <section>
            <div className='hotels-container'>
                <div className='hotel-item-joinville' alt="Hotel Le Canard - Joinville">
                <Link className='hotel-item-title' to="/joinville" style={{ marginTop: "auto" }}>
                    Le Canard Joinville
                </Link>
                <div className='hotel-item-text' >Rua XV De Novembro, 2075 - Joinville/SC</div>
                <div className='hotel-item-text' >Telefone: 47 3433-5033</div>
                <div className='hotel-item-text' >joinville@lecanard.com.br</div>

                <Link className='hotel-item-title' to="/joinville" style={{ marginBottom: "auto" }}>
                    Saiba mais <MdChevronRight className='chevron-right-more'/> 
                </Link>
                </div>

                <div className='hotel-item-lages' alt="Hotel Le Canard - Lages">
                <Link className='hotel-item-title' to="/lages" style={{ marginTop: "auto" }}>
                    Le Canard  Lages
                </Link>
                <div className='hotel-item-text' >Avenida Presidente Vargas, 255 - Lages/SC</div>
                <div className='hotel-item-text' >Telefone: 49 99110-0244</div>
                <div className='hotel-item-text' >lages@lecanard.com.br</div>

                <Link className='hotel-item-title' to="/lages" style={{ marginBottom: "auto" }}>
                    Saiba mais <MdChevronRight className='chevron-right-more' />
                </Link>
                </div>
            </div>

        </section>
    )
}

export default HotelLink;