import React from "react";
import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import { Link } from 'react-router-dom';
import './style.css';


const Localizacao = ({ endereco, email, telefone, rotaEmail, rotaTelefone, imgSrc, urlMaps }) => {
    
    const InfoEndereco = {
        titulo: 'Venha conhecer',
        BtnContato: 'Entrar em contato',
    }

    return (
        <section className="endereco-wrapper">
            <div className="end-mapa-wrapper-left">
                <div title="Clique para endereço" rel="noreferrer" alt="Localização Hotel - Le Canard">
                    <iframe src={urlMaps}
                        className='mapa'
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title='Maps-Trianon'
                    />
                </div>
            </div>
            <div className="end-info-wrap-right">
                <h2>{`${InfoEndereco.titulo}`}</h2>
                <ul>
                    <li>
                        <p><i className="end-info-icones"><GoLocation /></i>
                            {endereco}
                        </p>
                    </li>
                    <li>
                        <a href={rotaEmail} target="blank" alt="Email - Hotel Le Canard"><i className="end-info-icones"><AiOutlineMail /> </i>
                            {email}
                        </a>
                    </li>
                    <li>
                        <a href={rotaTelefone} target="blank" alt="Telefone - Hotel Le Canard"><i className="end-info-icones"><BsTelephone /> </i>
                            {telefone}
                        </a>
                    </li>
                </ul>
                <div className="end-btn-wrap-contato">
                    <Link to="/contato" alt="Entre em contato">
                        {`${InfoEndereco.BtnContato}`} <span className="end-icon-btn-contato"><IoIosArrowForward /></span>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Localizacao;