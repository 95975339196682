import React, { useState, memo } from 'react'
import { useNavigate } from "react-router-dom"
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { FaRegBuilding, FaUser, FaChild } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { LogoHeader } from '../../assets/icons';
import { HiOutlineChevronDown } from 'react-icons/hi';

import InputDate from '../InputDate';
import InputComboBox from '../InputComboBox';
import SelectHotel from '../SelectHotel';
import './styles.css';

export default function Checkin() {

    const hotels = [
        { value: '5c646d5be74ff237e458f09c', label: 'Joinville' },
        { value: '5dc55652ab41d41f8063cf10', label: 'Lages' },
    ];

    const adults = [
        { value: '1', label: '1 adulto' },
        { value: '2', label: '2 adultos' },
        { value: '3', label: '3 adultos' },
        { value: '4', label: '4 adultos' },
        { value: '5', label: '5 adultos' },
        { value: '6', label: '6 adultos' }
    ];

    const childen = [
        { value: '0', label: '0 crianças' },
        { value: '1', label: '1 criança' },
        { value: '2', label: '2 crianças' },
        { value: '3', label: '3 crianças' },
        { value: '4', label: '4 crianças' },
        { value: '5', label: '5 crianças' },
        { value: '6', label: '6 crianças' }
    ];

    const [selectedHotel, setSelectedHotel] = useState(hotels[0])
    const [selectedAdult, setSelectedAdult] = useState(adults[0])
    const [selectedChild, setSelectedChild] = useState(childen[0])
    const [checkinDate, setCheckinDate] = useState(new Date())
    const [checkoutDate, setCheckOutDate] = useState(new Date(checkinDate.getFullYear(), checkinDate.getMonth(), checkinDate.getDate() + 1))

    const [openMenu, setOpenMenu] = useState(false)
    const [openCheckin, setOpenCheckin] = useState(false)
    const [openHoteis, setOpenHoteis] = useState(false)

    const toggleMenu = () => {
        setOpenMenu(!openMenu)
    }

    const toggleCheckin = () => {
        setOpenCheckin(!openCheckin)
    }

    const returnDate = (val) => {
        return `${val.getFullYear()}-${val.getMonth()}-${val.getDate()}`
    }

    const bookHotel = () => {
        if (checkinDate > checkoutDate) {
            alert('Selecione uma data de check-out inferior à data de check-in')
            return
        }

        window.open(`https://hbook.hsystem.com.br/booking?companyId=${selectedHotel.value}&adults=${selectedAdult.value}&children=${selectedChild.value}&checkin=${returnDate(checkinDate)}&checkout=${returnDate(checkoutDate)}`)
    }

    return (
        <>
            {
                window.innerWidth < 1000 ?
                    <>
                        <div className='header-checkin' style={{ height: openCheckin ? '225px' : '60px' }} >
                            <div className='header-checkin-accordion' style={{ display: openCheckin ? 'flex' : 'none', flexDirection: 'column', height: '0px' }} >

                                <div className='double-item double-margin-mobile' >
                                    <InputComboBox label={'hotel Le Canard'} options={hotels} setState={setSelectedHotel} icon={< FaRegBuilding className='header-item-icon' />} />
                                    <InputDate state={checkinDate} setState={setCheckinDate} label={'check-in'} min={new Date()} />
                                </div>

                                <div className='double-item double-margin-mobile' >
                                    <InputDate state={checkoutDate} setState={setCheckOutDate} label={'check-out'} min={new Date(checkinDate.getFullYear(), checkinDate.getMonth() - 1, checkinDate.getDate() + 1)} />
                                    <InputComboBox label={'adultos'} options={adults} setState={setSelectedAdult} icon={< FaUser className='header-item-icon' />} />
                                </div>

                                <div className='double-item double-margin-mobile' >
                                    <InputComboBox label={'crianças'} options={childen} setState={setSelectedChild} icon={< FaChild className='header-item-icon' />} />
                                    <div className='btn-book' >
                                        <div className='btn-book-text' onClick={() => bookHotel()} >Reservar</div>
                                    </div>
                                </div>

                            </div>

                            <div className='header-toggle-checkin' onClick={() => toggleCheckin()} >
                                {
                                    openCheckin ?
                                        <>
                                            <BsChevronCompactUp className='header-toggle-checkin-icon' />
                                        </>
                                        :
                                        <>
                                            <p className='header-toggle-checkin-text' >CLIQUE AQUI E FAÇA SUA RESERVA</p>
                                            <BsChevronCompactDown className='header-toggle-checkin-icon' />
                                        </>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='header-checkin' style={{ paddingBottom: '20px' }} >

                            <div className='double-item double-margin-mobile' >
                                <InputComboBox label={'hotel Le Canard'} options={hotels} setState={setSelectedHotel} icon={< FaRegBuilding className='header-item-icon' />} />
                                <InputDate state={checkinDate} setState={setCheckinDate} label={'check-in'} min={new Date()} />
                            </div>

                            <div className='double-item double-margin-mobile' >
                                <InputDate state={checkoutDate} setState={setCheckOutDate} label={'check-out'} min={new Date(checkinDate.getFullYear(), checkinDate.getMonth() - 1, checkinDate.getDate() + 1)} />
                                <InputComboBox label={'adultos'} options={adults} setState={setSelectedAdult} icon={< FaUser className='header-item-icon' />} />
                            </div>

                            <div className='double-item double-margin-mobile' >
                                <InputComboBox label={'crianças'} options={childen} setState={setSelectedChild} icon={< FaChild className='header-item-icon' />} />
                                <div className='btn-book' >
                                    <div className='btn-book-text' onClick={() => bookHotel()} >Reservar</div>
                                </div>
                            </div>

                        </div>
                    </>
            }
        </>
    )
}
