import React from 'react';
import { GiChart, GiTable, GiAmplitude } from 'react-icons/gi';
import { TbLicense, TbMicrophone } from 'react-icons/tb';
import { FaWifi, FaThinkPeaks} from 'react-icons/fa';
import './Eventos.css';

    // Rotas 
import BannerHotel from '../../components/BannerHotel';
import SalaL from '../../components/SalasDeEventos/SalaEventoLeft';
import SalaR from '../../components/SalasDeEventos/SalaEventoRight';
import GroupIcons from '../../components/GroupIcons';
import EnvIcone from '../../components/EnvIcone';
import CoffeBreak from '../../components/CoffeBreak';
import Formulario from '../../components/Formulario';
    // Imagens
import Banner from '../../assets/BannerEventos.webp';

const Eventos = () => {
    
    const InfoEventos = {
        tituloPrincipal: 'Eventos',
        tituloSessao: 'Equipamentos',
        describeEquip: 'Equipamentos contratados a parte.',
    }
    
    const IconsInfoEvento = {
        info01: 'Flip Chart',
        info02: 'Tela de Projeção',
        info03: 'Mesas de Trabalho',
        info04: 'Porta Banner',
        info05: 'Wi-fi',
        info06: 'Caixa Amplificadora de Som',
        info07: 'Microfone',
    }
    
    return (
        <>
            <BannerHotel banner={Banner} titulo={`${InfoEventos.tituloPrincipal}`}/>
            <div className='evento-espacamento-topo'></div>
            <SalaL/>
            <SalaR/>
            <div className='evento-titulo-sessao-equipamentos'><h2>{`${InfoEventos.tituloSessao}`}</h2></div>
            <GroupIcons>
                <EnvIcone informacaodoIcone={`${IconsInfoEvento.info01}`} NewIcons={<TbLicense/>}/>
                <EnvIcone informacaodoIcone={`${IconsInfoEvento.info02}`} NewIcons={<GiChart/>}/>
                <EnvIcone informacaodoIcone={`${IconsInfoEvento.info03}`} NewIcons={<GiTable/>}/>
                <EnvIcone informacaodoIcone={`${IconsInfoEvento.info04}`} NewIcons={<FaThinkPeaks/>}/>
            </GroupIcons>
            <GroupIcons>
                <EnvIcone informacaodoIcone={`${IconsInfoEvento.info05}`} NewIcons={<FaWifi/>}/>
                <EnvIcone informacaodoIcone={`${IconsInfoEvento.info06}`} NewIcons={<GiAmplitude/>}/>
                <EnvIcone informacaodoIcone={`${IconsInfoEvento.info07}`} NewIcons={<TbMicrophone/>}/>
            </GroupIcons>
            <p className='eventos-txt-equip'>{`${InfoEventos.describeEquip}`}</p>
            <CoffeBreak/>
            <Formulario/>
        </>
    )
}

export default Eventos;