import React from 'react';
import './style.css';

import Banner from '../../assets/Internet_Hotel_Le_CanarD.webp';

const Hero = () => {

    return (
        <section className='hero-wrapper'>
            <div className='hero-img-wrapper'>
                <img src={Banner} alt="Internet ilimitada - Hotel Le Canard"/>
            </div>
            <div className='hero-info-wrapper'>
                <h2>Internet</h2>
                <p className='hero-text'>
                    Nossa internet está pronta para o uso para o 
                    cliente ao pisar no hotel, antes mesmo do check-in.
                </p>
                <ul>
                    <li>Suporte 24h, em todos os dias da semana</li>
                    <li>Velocidade alta e eficaz</li>
                    <li>Estabilidade total, independente da quantidade de acessos</li>
                </ul>
            </div>
        </section>
    )
}

export default Hero;