import React from 'react';
import './style.css';

import Imagem from '../../assets/ClientHabitue.webp';

const Cliente = ({titulo, descricao, subTitleOne, describeSubOne, subTitleTwo, describeSubTwo }) => {

    return (
        
        <section className='habitue-wrapper'>
            <div className='habitue-wrap-left'>
                <div className='habitue-posi-left'>
                    <div className='habitue-titulo-wrap-left'>
                        <h2>{titulo}</h2>
                        <p>{descricao}</p>
                    </div>
                    <div className='habitue-colunas-wrap-text-left'>
                        <div className='habitue-info-coluna-text-left'>
                            <h4>{subTitleOne}</h4>
                            <p>{describeSubOne}</p>
                        </div>
                        <div className='habitue-info-coluna-text-left'>
                            <h4>{subTitleTwo}</h4>
                            <p>{describeSubTwo}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='habitue-wrap-right'>
                <div className='habitue-wrap-image-right'>
                    <img src={Imagem} alt="ClCliente - Hotel Le Canard"/>
                </div>
            </div>
        </section>
    )
} 

export default Cliente;