import React, { useState, useEffect, memo } from 'react'
import { HiOutlineChevronDown } from 'react-icons/hi';
import { LogoHeader } from '../../assets/icons';
import { useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom';
import './styles.css';

import SelectHotel from '../SelectHotel';
import Checkin from '../Checkin';

function Header() {

  const navigate = useNavigate()
  console.log(navigate)

  const hotels = [
    { value: '5c646d5be74ff237e458f09c', label: 'Joinville' },
    { value: '5dc55652ab41d41f8063cf10', label: 'Lages' },
  ];

  const [winWidth, setwinWidth] = useState(window.innerWidth)

  const [openMenu, setOpenMenu] = useState(false)
  const [openHoteis, setOpenHoteis] = useState(false)

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  useEffect(() => {

    function handleResize() {
      setwinWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

  }, [])

  return (
    <div className='header-container' style={{ width: `${winWidth}px` }} >
      <div className='header-menus' >

        <div className='menu-mobile-container' onClick={toggleMenu} >
          <div className='menu-mobile-bar' style={openMenu ? { transform: 'rotate(45deg)', top: '15px' } : { transform: 'rotate(0deg)', top: '0' }} />
          <div className='menu-mobile-bar' style={{ display: openMenu ? 'none' : 'block' }} />
          <div className='menu-mobile-bar' style={openMenu ? { transform: 'rotate(-45deg)', top: '-8px' } : { transform: 'rotate(0deg)', top: '0' }} />
        </div>

        <div className='header-logo'>
          <Link to="/" alt="Hotel - Le Canard">
            <LogoHeader alt="Le Canard - Hotel em Santa Catarina" />
          </Link>
        </div>

        <div className='header-row' style={{ height: openMenu ? '375px' : window.innerWidth < 1000 ? '0px' : '50px' }} >

          <Link className='header-item' to="/" alt="Home - Le Canard ">Início</Link>
          <SelectHotel options={hotels} >
            <div className='header-item' style={{ marginRight: '5px', marginTop: '15px' }} >Hotéis</div>
          </SelectHotel>

          <Link className='header-item' to="/lazer"   alt="Lazer - Le Canard ">Lazer</Link>
          <Link className='header-item' to='/eventos' alt="Eventos - Le Canard ">Eventos</Link>
          <Link className='header-item' to="/cliente" alt="Clientes - Le Canard ">Cliente Habitue</Link>
          <Link className='header-item' to="/contato" alt="Contato - Le Canard ">Contato</Link>

          <Link className='header-item-mobile' onClick={() => toggleMenu()} style={{ display: openMenu ? 'flex' : 'none' }} to="/" alt="Lazer - Le Canard">
            Início
          </Link>
          {
            window.innerWidth < 1000 &&
            <div className='header-item-mobile'
              style={{ marginRight: '5px', display: openMenu ? 'flex' : 'none' }}
              onClick={() => setOpenHoteis(!openHoteis)}>
              Hotéis
              <HiOutlineChevronDown className='chevron-down' style={{ display: openMenu ? 'flex' : 'none', marginLeft: '5px', marginRight: '0px' }} />
            </div>
          }
          <Link to={'/joinville'} onClick={() => toggleMenu()} className='header-item-mobile-option' style={{ display: openHoteis && openMenu ? 'flex' : 'none' }} alt="Joinville - Le Canard ">
            Joinville
          </Link>
          <Link to={'/lages'} onClick={() => toggleMenu()} className='header-item-mobile-option' style={{ display: openHoteis && openMenu ? 'flex' : 'none' }} alt="Lages - Le Canard ">
            Lages
          </Link>
          <Link className='header-item-mobile' onClick={() => toggleMenu()} style={{ display: openMenu ? 'flex' : 'none' }} to="/lazer" alt="Lazer - Le Canard ">
            Lazer
          </Link>
          <Link className='header-item-mobile' onClick={() => toggleMenu()} style={{ display: openMenu ? 'flex' : 'none' }} to="/eventos" alt="Eventos - Le Canard ">
            Eventos
          </Link>
          <Link className='header-item-mobile' onClick={() => toggleMenu()} style={{ display: openMenu ? 'flex' : 'none' }} to="/cliente" alt="Cliente - Le Canard ">
            Cliente Habitue
          </Link>
          <Link className='header-item-mobile' onClick={() => toggleMenu()} style={{ display: openMenu ? 'flex' : 'none' }} to="/contato" alt="Contato - Le Canard ">
            Contato
          </Link>
        </div>
      </div>

      <Checkin />

    </div>
  )
}

export default memo(Header)