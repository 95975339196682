import React, { memo, useState } from 'react'
import Select from "react-select";
import OutsideClickHandler from 'react-outside-click-handler';
import { HiOutlineChevronDown } from 'react-icons/hi';

import './styles.css';

function InputComboBox({ setState, label, options, icon }) {
    const [value, setValue] = useState(options[0])
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div style={{ marginTop: 'auto', marginBottom: 'auto' }} >
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)} >
                <div className='header-item-label' > {label} </div>
                <div className='header-item-input' onClick={() => setIsOpen(!isOpen)} onBlur={() => setIsOpen(false)} >
                    {icon}
                    <div className='header-item-text'  > {value.label} </div>
                    <HiOutlineChevronDown className='chevron-down' />
                </div>

                <Select
                    styles={{
                        control: () => ({
                            opacity: 0,
                            position: 'absolute',
                            zIndex: -1
                        }),
                        option: (styles, { isFocused, isSelected }) => {
                            return {
                                ...styles,
                                backgroundColor: isSelected ? '#7f7f7e' : isFocused ? '#6b6b6b' : undefined,
                                color:  isSelected ? '#fff' : isFocused ? '#fff' : undefined
                            }
                        }
                    }}
                    value={value}
                    onChange={(value) => {
                        setValue(value)
                        setState(value)
                        setIsOpen(false)
                    }}
                    onBlur={() => setIsOpen(false)}
                    options={options}
                    menuIsOpen={isOpen}
                    closeMenuOnScroll={true}
                />
            </OutsideClickHandler>
        </div>
    )
}

export default memo(InputComboBox)