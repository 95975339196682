import React from 'react';
import './style.css';

import Carousel from '../Carousel/Carrousel';

import Carousel01 from '../../assets/Coffe01.webp'
import Carousel02 from '../../assets/Coffe02.webp'
import Carousel03 from '../../assets/Coffe03.webp'
import Carousel04 from '../../assets/Coffe04.webp'

const CoffeShop = () => {
    
    const InfoCOffe = {
        titulo: 'Coffee Shop',
        describe: 'Além de um café da manhã completo, o Le Canard também possui um Coffee Shop pronto para atender seus hóspedes em qualquer momento.',
    }
     
    return (
        <section className='coffe-wrapper'>
            <div className='coffe-content-wrapper'>
                <div className='coffe-info-wrapper'>
                    <h3>{`${InfoCOffe.titulo}`}</h3>
                    <p>{`${InfoCOffe.describe}`}</p>
                </div>
                <div className='coffe-carousel-wrapper'>
                    <div className='coffe-escopo-carousel'>
                        <Carousel>
                            <img src={Carousel01} alt="Banner - Hotel Le Canard" />
                            <img src={Carousel02} alt="Banner - Hotel Le Canard" />
                            <img src={Carousel03} alt="Banner - Hotel Le Canard" />
                            <img src={Carousel04} alt="Banner - Hotel Le Canard" />
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CoffeShop;