import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../Carousel/Carrousel';
import './SalaEventos.css';

    // Imagens 
import Carousel1 from '../../assets/SalaDeReuniao01.png';
import Carousel2 from '../../assets/SalaDeReuniao02.png';
import Carousel3 from '../../assets/SalaDeReuniao03.png';
import Carousel4 from '../../assets/SalaDeReuniao04.png';

const SalaL = () => {
    
    return (
        <section className='salaL-wrapper'>
            <div className='salaL-wrapper-left'>
                <div className='salaL-info-wrapper'>
                    <div className='salaL-textos-wrap'>
                        <h2>Joinville</h2>
                        <p>
                            LeCanard Joinville possui o espaço ideal para o sucesso do seu evento.
                        </p>
                    </div>
                    <div className='salaL-list-wrapper'>
                        <ul className='salaL-nome-salas'>
                            <li><h4>Auditório</h4></li>
                            <li><h4>Universitária</h4></li>    
                            <li><h4>Forma em U</h4></li>    
                            <li><h4>Espinha de peixe</h4></li>        
                        </ul>

                        <ul>
                            <li><h4>Executivo</h4></li>
                            <li><p>25 pessoas</p></li>
                            <li><p>25 pessoas</p></li>        
                            <li><p>15 pessoas</p></li>        
                            <li><p>12 pessoas</p></li>                
                        </ul>

                        <ul>
                            <li><h4>Convenções</h4></li>
                            <li><p>80 pessoas</p></li>
                            <li><p>80 pessoas</p></li>        
                            <li><p>24 pessoas</p></li>        
                            <li><p>48 pessoas</p></li>        
                        </ul>    
                    </div>
                    <div className='salasL-wrapper-btn'>
                        <Link title="Clique para agendar seu evento!" to="/contato" alt="Agende seu evento!">
                            Agende seu evento!
                        </Link>
                    </div>
                </div>
            </div>
            
            <div className='salaL-wrapper-right'>
                <div className='salas-img-wrapper'>
                    <div className='salas-img-wrap-carousel'>
                        <Carousel>
                            <img src={Carousel1} alt="Salas de reunião - Hotel Le Canard"/>
                            <img src={Carousel2} alt="Salas de reunião - Hotel Le Canard"/>
                            <img src={Carousel3} alt="Salas de reunião - Hotel Le Canard"/>
                            <img src={Carousel4} alt="Salas de reunião - Hotel Le Canard"/>
                        </Carousel>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default SalaL;