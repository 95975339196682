import React from 'react';
import './style.css';

const Slider = ({Imagem, texto}) => {

    return (
        <div className='carrousel-img-container' >
            <img className='carroussel-item' src={Imagem} alt="Hotel Le Canard"/>
            <div className='carroussel-overlay-container' >
              <div className='carroussel-overlay-text' >
                {texto}
              </div>
            </div>
        </div>
    )
}

export default Slider;