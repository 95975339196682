import React from 'react';
import './style.css';

const GroupIcons = (props) => {

    return (
        <main>
            <div className="icones-wrapper-joinville espacamento-icones-joinville">
                {props.children}
            </div>
        </main>
    )
}

export default GroupIcons;