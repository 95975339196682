import React, { useState } from 'react';
import { MdBed, MdOutlineDirectionsCarFilled } from 'react-icons/md';
import { GiMoneyStack, GiCoffeeCup } from 'react-icons/gi';
import { IoMdWoman } from 'react-icons/io';
import { BiMap } from 'react-icons/bi';
import './styles.css';

import Carousel from '../../components/Carousel/Carrousel';
import Slider from '../../components/Carousel/Slider';
import HotelLink from '../../components/HotelLink';

import HomeSmart from '../../assets/home_smart.webp';
import Slider1 from '../../assets/home_slider1.webp';
import Slider2 from '../../assets/home_slider2.webp';
import Slider3 from '../../assets/home_slider3.webp';
import Slider4 from '../../assets/home_slider4.webp';
import Slider5 from '../../assets/home_slider5.webp';
import Slider6 from '../../assets/home_slider6.webp';
import Slider7 from '../../assets/home_slider7.webp';
import { Popup } from '../../components/PopUp';

export default function Home() {
  

  // const [scrollX, setScrollX] = useState(0)

  return (
    <div>
      
      <div className="carroussel-container">
        <Carousel>
          <div className="carrousel-img-container">
            <img
              className="carroussel-item"
              src={Slider1}
              alt="Hotel Le Canard"
            />
          </div>

          <Slider Imagem={Slider2} texto={'Localização privilegiada'} />
          <Slider Imagem={Slider3} texto={'Alimentação completa'} />
          <Slider Imagem={Slider4} texto={'Academia 24 horas'} />
          <Slider Imagem={Slider5} texto={'Quartos conjugados'} />
          <Slider Imagem={Slider6} texto={'Ala feminina exclusiva'} />
          <Slider Imagem={Slider7} texto={'Espaço ideal para eventos'} />
        </Carousel>
      </div>

      <div className="smart-container">
        <div className="smart-item">
          <h1 className="smart-item-title">Escolha inteligente</h1>
          <p className="smart-item-text">
            Design urbano e contemporâneo, ambiente calmo, quartos inteligentes
            e confortáveis. O melhor custo-benefício você só encontra aqui!
          </p>
        </div>
        <img
          className="smart-item-image"
          src={HomeSmart}
          alt="Hotel Le Canard"
        />
      </div>

      <div className="benefits-container">
        <div className="double-item">
          <div className="benefits-item">
            <MdBed className="benefits-item-icon" />
            <h3 className="benefits-item-title">Quartos novos e acolhedores</h3>
            <p className="benefits-item-text">
              Limpeza e cuidados pensando sempre no melhor para o cliente
            </p>
          </div>

          <div className="benefits-item">
            <GiMoneyStack className="benefits-item-icon" />
            <h3 className="benefits-item-title">Melhor Custo x Benefício</h3>
            <p className="benefits-item-text">
              Preços únicos atrelados a vantagens e comodidades especiais
            </p>
          </div>
        </div>

        <div className="double-item">
          <div className="benefits-item">
            <GiCoffeeCup className="benefits-item-icon" />
            <h3 className="benefits-item-title">Alimentação completa</h3>
            <p className="benefits-item-text">
              Café da manhã completo, assim como restaurante preparado
            </p>
          </div>

          <div className="benefits-item">
            <MdOutlineDirectionsCarFilled className="benefits-item-icon" />
            <h3 className="benefits-item-title">Estacionamento privativo</h3>
            <p className="benefits-item-text">
              Estacionamento exclusivo para os clientes do Le Canard
            </p>
          </div>
        </div>

        <div className="double-item">
          <div className="benefits-item">
            <BiMap className="benefits-item-icon" />
            <h3 className="benefits-item-title">Localização privilegiada</h3>
            <p className="benefits-item-text">
              Le Canard está situado perto dos principais pontos da cidade
            </p>
          </div>

          <div className="benefits-item">
            <IoMdWoman className="benefits-item-icon" />
            <h3 className="benefits-item-title">Ala feminina exclusiva</h3>
            <p className="benefits-item-text">
              Todo um andar em que só mulheres podem se hospedar
            </p>
          </div>
        </div>
      </div>
      <HotelLink />
    </div>
  );
}
