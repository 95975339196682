import React from 'react';
import './Lazer.css';

// Rotas 
import BannerHotel from '../../components/BannerHotel';
import RestauranteImg from '../../assets/RestauranteLeCanardi.webp';
import Hero from '../../components/Hero';
import Carousel from '../../components/Carousel/Carrousel';
import CafedaManha from '../../components/CafedaManha';
import SliderText from '../../components/Carousel/SliderText';
import CoffeShop from '../../components/CoffeShop';

// Imagens 
import Banner from '../../assets/BannerLazer.webp';


const Lazer = () => {
    
    const InfoLazerSeo = {
        titulo: 'Lazer',
        tituloCarousel: 'Academia',
        descricaoCarousel: 'Eleita pela CVB como a melhor academia, com funcionamento 24h.',
    }
    
    return (
        <>
            <BannerHotel titulo={`${InfoLazerSeo.titulo}`} banner={Banner}/>
            <div className='lazer-espacamento-hero'></div>
            <Hero/>
            <div className='lazer-espacamento-carousel'></div>
            <Carousel>
                <div className='Lazer-carrousel01'>
                    <SliderText 
                        titulo={`${InfoLazerSeo.tituloCarousel}`}
                        info={`${InfoLazerSeo.descricaoCarousel}`}
                    />
                </div>
                <div className='Lazer-carrousel02'>
                    <SliderText 
                        titulo={`${InfoLazerSeo.tituloCarousel}`}
                        info={`${InfoLazerSeo.descricaoCarousel}`}
                    />
                </div>
                <div className='Lazer-carrousel03'>
                    <SliderText 
                        titulo={`${InfoLazerSeo.tituloCarousel}`}
                        info={`${InfoLazerSeo.descricaoCarousel}`}
                    />
                </div>
            </Carousel>
            <div className='lazer-espacamento-carousel'></div>
            <CafedaManha imgSrc={RestauranteImg} />
            <CoffeShop/>
        </>
    )
}

export default Lazer;