import React from "react";
import './style.css';

import ImageRestaurante from '../../assets/Restaurante.webp';

const Restaurante = () => {

    return (
        <div className="Restaurante-wrapper">
            <img src={ImageRestaurante} alt="Restaurante - Hotel Le Canard" />
            <div className="rest-info-wrappper">
                <h2>Restaurante</h2>
                <div className="rest-text-wrapper">
                    <p>
                        O Le Canard Joinville conta com restaurante próprio, sendo 
                        uma cozinha elaborada com certificação da Sebrae.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Restaurante;