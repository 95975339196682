import React from 'react';
import './style.css';

import Banner from '../../assets/ImageForm.webp';

const Formulario = () => {
    
    const ConteudoForm = {
        RadioOne: 'LeCanard Joinville',
        RadioTwo: 'LeCanard Lages',
    }

    return (
        <main className='form-wrapper'>
            <div className='form-text-wrap-decoration'>
                <img src={Banner} alt="Contato - Hotel Le Canard" />
            </div>
            <div className='form-wrapper-right'>
                <div className='form-escopo'>
                    <form action="https://formsubmit.co/comercial.joinville@lecanard.com.br" method="POST">
                        <input type="hidden" name="_cc" value="lages@lecanard.com.br,admlages@lecanard.com.br,recepcaolages@lecanard.com.br,reservalages@lecanard.com.br,eventos.lages@lecanard.com.br" />
                        <div className='form-radio-box'>
                            <input type="radio" name='hotel' value="joinville" required /><p>{`${ConteudoForm.RadioOne}`}</p>
                            <input id='form-inputTwo' type="radio" name='hotel' value="lages" required /><p>{`${ConteudoForm.RadioTwo}`}</p>
                        </div>
                        <div className='form-wrap-inputs'>
                            <p className='form-describe-inputs'>Nome*</p>
                            <input type="text" name="Nome" placeholder='Digite seu nome' required />
                            <p className='form-describe-inputs'>E-mail*</p>
                            <input type="email" name="E-mail" placeholder='Digite seu e-mail' required />
                            <p className='form-describe-inputs'>Assunto*</p>
                            <input type="text" name="Assunto" placeholder='Sobre o que deseja conversar?' required />
                            <p className='form-describe-inputs'>Mensagem*</p>
                            <textarea name="Mensagem" placeholder='Escreva sua mensagem' required />
                            <input type="hidden" name="_captcha" value="false" />
                            <input type="hidden" name="_next" value="http://lecanardprototipo.aprovacao.vegapix.com.br/" />
                            <button className='form-btn' type='submit'>Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Formulario;