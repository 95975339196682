import React from 'react';
import './style.css';

const BannerHotel = ({ banner, titulo, describe }) => {

    return (
        <>
           <div className='bannertop-espacamento'></div>
            <div className="banner-wrapper">
                <img src={banner} alt="Hotel Le Canard" />
            </div>
            <h1 className="banner-titulo">{titulo}</h1>
            <div className="banner-wrapper-sobre">
                <p>{describe}</p>
            </div>
        </>
    )
}

export default BannerHotel;