import React, { memo, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { HiOutlineChevronDown } from 'react-icons/hi';
import Select from "react-select";
import OutsideClickHandler from 'react-outside-click-handler';


import './styles.css';

function SelectHotel(props) {

    const navigate = useNavigate()

    const [value, setValue] = useState(props.options[0])
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div style={{ marginTop: 'auto', marginBottom: 'auto' }} >
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)} >
                <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => setIsOpen(!isOpen)} >
                    {props.children}
                    <HiOutlineChevronDown className='chevron-down' />
                </div>

                <Select
                    styles={{
                        control: () => ({
                            opacity: 0,
                            position: 'absolute',
                            zIndex: -1
                        }),
                        option: (styles, { isFocused, isSelected }) => {
                            return {
                                ...styles,
                                backgroundColor: isSelected ? '#7f7f7e' : isFocused ? '#6b6b6b' : undefined,
                                color:  isSelected ? '#fff' : isFocused ? '#fff' : undefined
                            }
                        }
                    }}
                    value={value}
                    onChange={(value) => {
                        setValue(value)
                        navigate(`/${value.label}`)
                        setIsOpen(false)
                    }}
                    onBlur={() => setIsOpen(false)}
                    options={props.options}
                    menuIsOpen={isOpen}
                    closeMenuOnScroll={true}
                />
            </OutsideClickHandler>
        </div>
    )
}

export default memo(SelectHotel)