import React from "react";

const SliderText = ({ titulo, info }) => {

    return (
        <div>
            <h2 className='lazer-titulo-carrousel'>
                {titulo}
            </h2>
            <p className='lazer-texto-carrousel'>
                {info}
            </p>
        </div>
    )
}

export default SliderText;