import React from "react";
import './style.css'

const CafedaManha = ({ imgSrc }) => {
    
    const infoWrapper = {
        titulo: 'Café da Manhã',
        describe: 'O mais completo café da manhã da categoria. Dispomos de um completo café da manhã com opções variadas e estação Light.',
    }
    
    return (
        <section className="joinville-cfdmanha-wrapper">
            <div className="joinville-cfdmanha-text-wrapper">
                <h2>{`${infoWrapper.titulo}`}</h2>
            </div>
            <div className="cfd-txt-wrapper">
                <p>{`${infoWrapper.describe}`}</p>
            </div>
            <div className="cfdmanha-image-wrapper">
                <img src={imgSrc} alt="Cafe da manha - Hotel Le Canard" />
            </div>
            <div className="cfd-coloracao-image"></div>
        </section>
    )
}

export default CafedaManha;