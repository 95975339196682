import React from 'react';
import { GiCoffeeCup, GiWeightLiftingUp, GiTable, GiShower, GiSnowflake1, GiBathtub, GiMirrorMirror } from 'react-icons/gi';
import { BiRestaurant, BiFridge, BiBed, BiFullscreen } from 'react-icons/bi'
import { CgScreen } from 'react-icons/cg';
import { FaWifi } from 'react-icons/fa';

// rotas 

import BannerHotel from '../../components/BannerHotel';
import QuartoR from '../../components/Quartos/quartosRight';
import QuartoL from '../../components/Quartos/QuartoLeft';
import CafedaManha from '../../components/CafedaManha';
import Restaurante from '../../components/Restaurante';
import Estacionamento from '../../components/Estacionamento';
import Localizacao from '../../components/localizacao';
import GroupIcons from '../../components/GroupIcons';
import EnvIcone from '../../components/EnvIcone';

// imagens
import Banner from '../../assets/BannerJoinville.webp';
import RestauranteImg from '../../assets/RestauranteLeCanardi.webp';
import ImagemEstacionamento from '../../assets/Estacionamento_Hotel_Le_Canard.webp';
import MapaImage from '../../assets/Localizacao_Le_Canard_Joinville.webp';

import BannerQuartoRight from '../../assets/quarto-joinville.webp';
import DuploTwin from '../../assets/DuploTwin.webp';
import CamaDeCasal from '../../assets/CamaCasal.webp';
import SuperiorTwin from '../../assets/SuperiorTwin.webp';
import SuperiorDuplo from '../../assets/SuperiorDuplo.webp';
import SuiteSuperior from '../../assets/SuiteSuperior.webp';
import AlaFeminina from '../../assets/AlaFeminina.webp';

const UnJoinville = () => {
    
    const Seo = {
        titulo: 'Hotel Le Canard - Joinville',
        altPadrao: 'Hotel Le Canard - Joinville',
        altBannerLeft: 'Cama de Casal - Hotel Le Canard - Joinville',
        describe: 'O Le Canard Joinville está estrategicamente localizado na entrada principal da cidade, na Rua XV de Novembro, 2.075, acesso facilitado aos executivos e turistas que chegam a Joinville, por isso é eleito como um dos hotéis mais bem situados do município.',
    }
    
    const infoProdutos = {
        descriptionIcone01: 'TV LED 32’',
        descriptionIcone02: '2 Camas Solteiro',
        descriptionIcone03: '17 m²',
        descriptionIcone04: 'Cama Queen',
        descriptionIcone05: 'Cama Solteiro King',
        descriptionIcone06: 'TV LED 42’',
        descriptionIcone07: '2 Camas Solteiro King',
        descriptionIcone08: '19 m²',
        descriptionIcone09: 'Hidromassagem',
        descriptionIcone10: 'Espelho Maquiagem',
        botaoReserva: 'Quero reservar',
        botaoFotos: 'Ver mais fotos',
    }
    const infoProdutosQR = {
        titulo: 'Executivo Twin',
        titulo02: 'Duplo Twin',
        titulo03: 'Superior Duplo',
        titulo04: 'Ala de quartos majoritariamente femininos',
        describe: 'O Executivo Twin conta com um ambiente aconchegante, no qual possui 1 cama queen, TV LED 32 polegadas, frigobar sempre abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
        describe02: 'O Duplo Twin conta com um ambiente aconchegante, no qual possui 1 cama de solteiro king, TV LED 32 polegadas, frigobar sempre abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
        describe03: 'O Superior Duplo está situado em uma nova ala do hotel, contando com uma decoração moderna, televisão maior, melhor isolamento acústico, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
        describe04: 'Pensando no bem estar e na privacidade feminina, o Le Canard possui uma ala de quartos exclusiva para mulheres. Além de tudo que é oferecido nos quartos Superiores, esses ainda contam com um espelho de quase 2m, chapinha e um secador super potente.',
    }
    const infoProdutosQL = {
        titulo: 'Executivo Duplo',
        titulo02: 'Superior Twin',
        titulo03: 'Suíte Superior',
        descricao: 'O Executivo Duplo conta com um ambiente aconchegante, no qual possui 1 cama queen, TV LED 32 polegadas, frigobar sempre abastecido, além de todas as comodidades e vantagens oferecidas no Le Canard.',
        descricao02: 'O Superior Twin está situado em uma nova ala do hotel, contando assim com uma decoração mais moderna, televisão maior, um melhor isolamento acústico, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
        descricao03: 'A Suíte Superior está situada em uma nova ala do hotel, contando com uma decoração moderna, televisão maior, melhor isolamento acústico, uma banheira com hidromassagem, além de todas as outras vantagens e comodidades oferecidas pelo Le Canard.',
    }
    
    const InfoIconesJoinville = {
        info01: 'Café da manha',
        info02: 'Academia 24h',
        info03: 'Wi-fi',
        info04: 'Restaurante',
        info05: 'Frigobar',
        info06: 'Mesa de Trabalho',
        info07: 'Duchas com pressurizador individual',
        info08: 'Ar condicionado split quente e frio',
    }
    const InfoLocalizacao = {
        endereco: 'Rua XV De Novembro, 2075 | Joinville/SC',
        email: 'joinville@lecanard.com.br',
        telefone: '47 3433-5033 ',
    }

    return (
        <>
            <BannerHotel banner={Banner} titulo={`${Seo.titulo}`} describe={`${Seo.describe}`} />
            <GroupIcons>
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info01}`} NewIcons={<GiCoffeeCup />} />
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info02}`} NewIcons={<GiWeightLiftingUp />} />
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info03}`} NewIcons={<FaWifi />} />
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info04}`} NewIcons={<BiRestaurant />} />
            </GroupIcons>
            <GroupIcons>
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info05}`} NewIcons={<BiFridge />} />
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info06}`} NewIcons={<GiTable />} />
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info07}`} NewIcons={<GiShower />} />
                <EnvIcone informacaodoIcone={`${InfoIconesJoinville.info08}`} NewIcons={<GiSnowflake1 />} />
            </GroupIcons>

            {/* quartos  */}

            <QuartoR
                titulo={`${infoProdutosQR.titulo}`} descricao={`${infoProdutosQR.describe}`} btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${Seo.altPadrao}`} imagem={BannerQuartoRight}
                icons=
                {[{ info: `${infoProdutos.descriptionIcone01}`, icone: <CgScreen /> },
                { info: `${infoProdutos.descriptionIcone04}`, icone: <BiBed /> },
                { info: `${infoProdutos.descriptionIcone03}`, icone: <BiFullscreen /> }
                ]}
                Banner1={BannerQuartoRight} Banner2={BannerQuartoRight} Banner3={BannerQuartoRight}
            />
            <QuartoL Image={CamaDeCasal} alt={`${Seo.altBannerLeft}`} titulo={`${infoProdutosQL.titulo}`} description={`${infoProdutosQL.descricao}`}
                BtnReserva={`${infoProdutos.botaoReserva}`} BtnFotos={`${infoProdutos.botaoFotos}`}
                icones=
                {[{ icone: <CgScreen />, infoIcone: `${infoProdutos.descriptionIcone01}` },
                { icone: <BiBed />, infoIcone: `${infoProdutos.descriptionIcone04}` },
                { icone: <BiFullscreen />, infoIcone: `${infoProdutos.descriptionIcone03}` }
                ]}
                Banner1={CamaDeCasal} Banner2={CamaDeCasal} Banner3={CamaDeCasal}
            />
            <QuartoR titulo={`${infoProdutosQR.titulo02}`} descricao={`${infoProdutosQR.describe02}`} btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${Seo.altPadrao}`} imagem={DuploTwin}
                icons=
                {[{ info: `${infoProdutos.descriptionIcone01}`, icone: <CgScreen /> },
                { info: `${infoProdutos.descriptionIcone05}`, icone: <BiBed /> },
                { info: `${infoProdutos.descriptionIcone03}`, icone: <BiFullscreen /> }
                ]}
                Banner1={DuploTwin} Banner2={DuploTwin} Banner3={DuploTwin}
            />
            <QuartoL Image={SuperiorTwin} alt={`${Seo.altBannerLeft}`} titulo={`${infoProdutosQL.titulo02}`} description={`${infoProdutosQL.descricao02}`}
                BtnReserva={`${infoProdutos.botaoReserva}`} BtnFotos={`${infoProdutos.botaoFotos}`}
                icones=
                {[{ icone: <CgScreen />, infoIcone: `${infoProdutos.descriptionIcone06}` },
                { icone: <BiBed />, infoIcone: `${infoProdutos.descriptionIcone07}` },
                { icone: <BiFullscreen />, infoIcone: `${infoProdutos.descriptionIcone08}` }
                ]}
                Banner1={SuperiorTwin} Banner2={SuperiorTwin} Banner3={SuperiorTwin}
            />
            <QuartoR
                titulo={`${infoProdutosQR.titulo03}`} descricao={`${infoProdutosQR.describe03}`} btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${Seo.altPadrao}`} imagem={SuperiorDuplo}
                icons=
                {[{ info: `${infoProdutos.descriptionIcone06}`, icone: <CgScreen /> },
                { info: `${infoProdutos.descriptionIcone04}`, icone: <BiBed /> },
                { info: `${infoProdutos.descriptionIcone08}`, icone: <BiFullscreen /> }
                ]}
                Banner1={SuperiorDuplo} Banner2={SuperiorDuplo} Banner3={SuperiorDuplo}
            />
            <QuartoL Image={SuiteSuperior} alt={`${Seo.altBannerLeft}`} titulo={`${infoProdutosQL.titulo03}`} description={`${infoProdutosQL.descricao03}`}
                BtnReserva={`${infoProdutos.botaoReserva}`} BtnFotos={`${infoProdutos.botaoFotos}`}
                icones=
                {[{ icone: <CgScreen />, infoIcone: `${infoProdutos.descriptionIcone06}` },
                { icone: <BiBed />, infoIcone: `${infoProdutos.descriptionIcone04}` },
                { icone: <BiFullscreen />, infoIcone: `${infoProdutos.descriptionIcone08}` },
                { icone: <GiBathtub />, infoIcone: `${infoProdutos.descriptionIcone09}` }
                ]}
                Banner1={SuiteSuperior} Banner2={SuiteSuperior} Banner3={SuiteSuperior}
            />
            <QuartoR titulo={`${infoProdutosQR.titulo04}`} descricao={`${infoProdutosQR.describe04}`} btnReserva={`${infoProdutos.botaoReserva}`}
                btnFotos={`${infoProdutos.botaoFotos}`} alt={`${Seo.altPadrao}`} imagem={AlaFeminina}
                icons=
                {[{ info: `${infoProdutos.descriptionIcone06}`, icone: <CgScreen /> },
                { info: `${infoProdutos.descriptionIcone04}`, icone: <BiBed /> },
                { info: `${infoProdutos.descriptionIcone08}`, icone: <BiFullscreen /> },
                { info: `${infoProdutos.descriptionIcone10}`, icone: <GiMirrorMirror /> }
                ]}
                Banner1={AlaFeminina} Banner2={AlaFeminina} Banner3={AlaFeminina}
            />
            <CafedaManha imgSrc={RestauranteImg} />
            <Restaurante />
            <Estacionamento imgSrc={ImagemEstacionamento} descricao={'O hotel possui um estacionamento próprio privativo, com ampla quantidade de vagas, inclusive para ônibus.'} />
            <Localizacao
                email={`${InfoLocalizacao.email}`}
                endereco={`${InfoLocalizacao.endereco}`}
                telefone={`${InfoLocalizacao.telefone}`}
                imgSrc={MapaImage}
                urlMaps={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20233.713961251644!2d-48.87403297862709!3d-26.298991869655477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94deb0177a06200d%3A0x341b47295c828b1f!2sLe%20Canard%20Joinville!5e0!3m2!1spt-BR!2sbr!4v1665498628265!5m2!1spt-BR!2sbr'}
            />
        </>
    )

}

export default UnJoinville;