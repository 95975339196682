import React from 'react';
import './contato.css';

// Rotas
import BannerHotel from '../../components/BannerHotel';
import Formulario from '../../components/Formulario';

  // Imagens
import Banner from '../../assets/BannerContato.webp';

export default function Contact() {
  
  return (
    <>
      <BannerHotel banner={Banner} titulo={'Contato'} />
      <div className='contato-espacamento-form'></div>
      <Formulario/>
    </>
  )
}
